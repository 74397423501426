import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Box, Modal } from '@mui/material'
import { addDays, format, subDays } from 'date-fns' // eslint-disable-line import/no-duplicates
import Router from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import useSWR, { useSWRConfig } from 'swr'

import { KarteComponent } from '@/components/pages/common/KarteComponent'
import { userContext } from '@/context/user'
import { Karte, hasComment } from '@/models/Karte'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
}

const App = () => {
  const { mutate } = useSWRConfig()
  const { selectedChild } = useContext(userContext)
  const inputEl = useRef<null | HTMLDivElement>(null)
  const [isDisplay, setIsDisplay] = useState(false)
  const [cookies, setCookie] = useCookies(['isTutorialDone'])

  const today = new Date()
  const params: string = new URLSearchParams({
    childId: selectedChild?.id,
    endDate: format(addDays(today, 1), 'yyyy-MM-dd'),
    startDate: format(subDays(today, 9), 'yyyy-MM-dd'),
  }).toString()
  const { data: kartes, error } = useSWR<Karte[], Error>(`/api/karte?${params}`)

  const handleSubmit = (): void => {
    mutate(`/api/karte?${params}`)
  }

  useEffect(() => {
    if (selectedChild?.status === 'pending') {
      Router.replace('/selectPlan')
    }
    if (selectedChild?.status === 'plan_selected') {
      Router.replace('/sending')
    }
    if (selectedChild?.status === 'text_sent') {
      Router.replace('/reservation')
    }

    if (inputEl.current) {
      inputEl.current.scrollIntoView()
      setIsDisplay(true)
    }

    if (cookies.isTutorialDone === 'completed') {
      setCookie('isTutorialDone', 'completed', { maxAge: 60 * 60 * 24 * 365 })
    }
  })

  if (error) {
    return <p>サーバーエラーです</p>
  }

  return (
    <>
      {kartes && (
        <>
          <Modal
            open={
              kartes.some((karte) => karte.content.type === 'lesson_karte') && cookies.isTutorialDone !== 'completed'
            }
            onClose={() => setCookie('isTutorialDone', 'completed', { maxAge: 60 * 60 * 24 * 365 })}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/rXpCdChM9eA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Box>
          </Modal>
          <Box
            className="container scrollableX"
            sx={{ height: '100%' }}
            style={{ display: isDisplay ? 'flex' : 'none' }}
          >
            {kartes.map((karte, index) => {
              const hasPrev = index !== 0
              const hasNext = index !== kartes.length - 1
              return (
                <section ref={kartes.length - 1 === index ? inputEl : null} key={karte.id} className="area">
                  <KarteComponent
                    karte={karte}
                    hasPrev={hasPrev}
                    hasNext={hasNext}
                    hasCommentAtPrev={hasPrev && hasComment(kartes[index - 1])}
                    hasCommentAtNext={hasNext && hasComment(kartes[index + 1])}
                    afterSubmit={handleSubmit}
                    readonly={false}
                  />
                </section>
              )
            })}
          </Box>
        </>
      )}
    </>
  )
}
App.requireAuth = true
export default App
